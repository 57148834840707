<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })"
                show-expand
                @item-expanded="itemExpanded"
                :expanded.sync="expanded"
                >

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
                    <v-btn 
                        icon
                        x-small
                        plain
                        class="expand-cust-btn"
                        @click="expand(true)" 
                        v-if="!isExpanded"
                    >
                        <v-icon>
                            fas fa-caret-right
                        </v-icon>
                    </v-btn>
                    <v-btn 
                        icon
                        x-small
                        plain
                        class="expand-cust-btn"
                        @click="expand(false)" 
                        v-else
                    >
                        <v-icon>
                            fas fa-caret-down
                        </v-icon>
                    </v-btn>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <div class="table-expanded-wrapper">

                            <template v-if="item._info">
                                <v-treeview 
                                    dense
                                    :items="item._info"
                                    class="cust-treeview-in-list"
                                    >
                                    <template v-slot:label="{ item }">

                                        <template v-if="item.text=='Link'">
                                                
                                            <div class="d-flex align-center">
                                                <span class="linked-docs-title">{{ $t(GetLinkedTypes[item.type]) }}</span>
                                                <div class="onlyReadData more-per-lab-wrap">
                                                    <v-doc-chip
                                                        v-for="(doc, index) in item.values"
                                                        :key="item.type+index"
                                                        :id="doc.values[6]"
                                                        :link-id="doc.values[0]"
                                                        :name="$t(GetDocumentTypes[doc.values[9]]) + ' № ' + doc.values[2] + ` ${$t('от_время')} ` + doc.values[3] + (doc.values[5] ? ' (' + doc.values[5] + ')' : '')" 
                                                        iconColor="#7289DA"
                                                        :is-linked-document="true"
                                                        :source-document-id="item.sourceDocumentId"
                                                    />
                                                </div>
                                            </div>

                                        </template>
                                        <template v-else-if="item.text=='Notify'">
                                            <div class="d-flex ctil-row-wrap">
                                                <div class="flex-grow-1">
                                                    <div class="elipsis-text-wrap">
                                                        <span>
                                                            {{ $t("Уведомление") }}
                                                        </span>
                                                        <span>
                                                            {{ item.values[0] }}
                                                        </span>
                                                        <span>
                                                            <v-employee-chip :id="item.values[3]" :name="item.values[2]" /> 
                                                        </span>
                                                        <div class="etw-text-container" v-tooltip.top-center="item.values[1]">
                                                            <span class="etw-text">
                                                                {{ item.values[1] }}
                                                            </span>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>                                           
                                        </template>
                                        <template v-else>
                                            {{ getLabel(item) }}
                                        </template>
                                        
                                    </template>
                                </v-treeview>
                            </template>
                            <v-progress-linear v-else
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>

                        </div>
                    </td>
                </template>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.correspondent`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.correspondent">
                            {{item.correspondent}}
                        </div>
                    </div>
                </template>
                
                <template v-slot:[`item.theme`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.theme">
                                {{ item.theme }}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.authorname`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-employee-chip :id="item.authorid" :name="item.authorname" />
                    </div>
                </template>

                <template v-slot:[`item.signername`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-employee-chip v-if="item.signername" :id="item.signeremployeeid" :name="item.signername" />
                    </div>
                </template>

                <!-- <template v-slot:[`item.sendstatus`]="{ item }"> -->
                <template v-slot:[`item.sendstatus`]="{  }">
                    <!-- <StatusChip :status="avaibleStatuses[$options.statusKeys[item.sendstatus]]"/> -->
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"
import adapter from "@/services/adapter";
import { mapGetters } from 'vuex';
// import StatusChip from '@/components/StatusChip';

export default {
    statusKeys:["outgoingNotSended", "outgoingSended", "outgoingSendedButRejected"],
    columnIndex: {
        'regnumber': 2,
        'regdate': 4,
        'correspondent': 5,
        'theme': 6,
        'authorname': 12
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    // components: {
    //     StatusChip
    // },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Корреспондент"), value: 'correspondent', width: '10%' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'},
                { text: i18n.t("Автор"), value: 'authorname', width: '150px'},
                { text: i18n.t("Подписал"), value: 'signername', width: '150px'},
                { text: i18n.t("Статус"), value: 'sendstatus', width: '150px', sortable: false}
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
            expanded: [],
        }
    },
    computed: {
        ...mapGetters('references', ['GetLinkedTypes', 'GetDocumentTypes']),
        options: {
            get: function() {
                return this.$store.getters['archive/outgoings/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('archive/outgoings/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['archive/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                ArchYear: this.status,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
        extendedTableFilter() {
            return this.$store.getters['archive/getExtendedFilter'];
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
    },
    methods: {
        async itemExpanded(val) {

            if (!val.value)
                return;
            
            this.$set(val.item, '_info', null)

            let response = await httpAPI({
                url: `api/correspondence/getdocumentchildren?id=${val.item.id}`,
                method: 'GET',               
            });
            
            let data = response?.data?.payload?.data?.object?.data;
            
            if (data)
            {
                let treeData = adapter.transformTreeData(null, data, val.item.id);
                this.$set(val.item, '_info', treeData)
            }   
        },
        getLabel(item){
            return item.values.toString();
        },
        itemRowBackground (item) {
            return item.readed === true ? '' : 'font-weight-bold';
        },
        resetPage() {
            this.$store.commit('archive/outgoings/SET_TABLE_OPTION_PAGE', 1);
        },        
        async getData(isRefresh = false) {
            if (this.status == '0')
            {
                this.total = 0;
                this.entities = [];
                this.loading = false;
                return;                
            }

            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);    

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/archive/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.expanded = [];
            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);    
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ArchYear, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;
            return { 
                SearchStr,
                ArchYear,
                DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
                OrderBy,
                OutgoingDocumentType: 2,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ...extendedFilterData
            };
        },
        dateFormat: sys.dateFormat,
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
